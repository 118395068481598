@use "sass:map";

//----------------------------------------
// Colors
//----------------------------------------

@import '../../base/base-colors';

// DEPRECATED - LEGACY
$color-brand: #70d5cf;
$color-brand-dark: #045056;

$color-gray-light-deprecated: #fafcfc;
$color-gray-medium-deprecated: #b8b8b8;
$color-gray-deprecated: #23292e;

// CO-BRANDING
$color-link-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-brand-2));
$color-link-co-branded: rgba($color-link-co-branded-color, 1);
$color-link-co-branded-hover: rgba($color-link-co-branded-color, 1.3);

$color-accent-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-accent));
$color-accent-co-branded: rgba($color-accent-co-branded-color, 1);
$color-accent-co-branded-hover: rgba($color-accent-co-branded-color, 0.3);
$color-accent-co-branded-disabled: rgba($color-accent-co-branded-color, 0.3);
$color-accent-co-branded-transparent: rgba($color-accent-co-branded-color, 0.1);

$color-accent-alt-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-brand-2));
$color-accent-alt-co-branded: rgba($color-accent-alt-co-branded-color, 1);
$color-accent-alt-co-branded-hover: rgba($color-accent-alt-co-branded-color, 0.3);
$color-accent-alt-co-branded-disabled: rgba($color-accent-alt-co-branded-color, 0.3);

$color-secondary-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-brand-4));
$color-secondary-co-branded: rgba($color-secondary-co-branded-color, 1) !default;
$color-secondary-co-branded-hover: rgba($color-secondary-co-branded-color, 0.75) !default;
$color-secondary-co-branded-disabled: rgba($color-secondary-co-branded-color, 0.3) !default;

$color-brand-3-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-brand-3));
$color-brand-3-co-branded: rgba($color-brand-3-co-branded-color, 1);
$color-brand-3-co-branded-hover: rgba($color-brand-3-co-branded-color, 0.75);

$background-brand-4-co-branded-color: var(--theme-accent-background-rgb, hexToRGB($color-brand-4));
$background-brand-4-co-branded: rgba($background-brand-4-co-branded-color, 1);
$background-brand-4-co-branded-hover: rgba($background-brand-4-co-branded-color, 0.3);

$color-brand-4-co-branded-color: var(--theme-accent-color-rgb, hexToRGB($color-brand-4));
$color-brand-4-co-branded: rgba($color-brand-4-co-branded-color, 1);
$color-brand-4-co-branded-hover: rgba($color-brand-4-co-branded-color, 0.75);
$color-brand-4-co-branded-disabled: rgba($color-brand-4-co-branded-color, 0.3);

$color-white-co-branded-color: var(--theme-accent-color-rgb, hexToRGB($color-white));
$color-white-co-branded: rgba($color-white-co-branded-color, 1);

$color-brand-co-branded: rgba(var(--theme-accent-background-rgb, hexToRGB($color-brand-1)), 1);
$color-gray-co-branded: rgba(var(--theme-accent-background-rgb, hexToRGB($color-gray)), 1);
$color-brand-5-co-branded-disabled: rgba(var(--theme-accent-color-rgb, hexToRGB($color-brand-5)), 0.3);

$color-link: $color-link-co-branded;

// CUSTOM MATERIAL
@import 'components/checkbox';
@import 'components/radio';
@import 'components/button-toggle';
@import 'components/calendar';
@import "components/tab-nav";
@import "components/slide-toggle";

// THEME
$color-primary: $color-accent-co-branded !default;
$color-primary-light: $color-accent-co-branded-hover !default;

$color-secondary: $color-secondary-co-branded;
$color-secondary-light: $color-secondary-co-branded-hover;

$color-tertiary: $color-gray;
$color-tertiary-light: $color-gray-light;

@import '../../base/context-colors';

// CONTEXT
$context-colors: map.merge(
        $context-colors,
        (
            primary-co: $color-brand-4-co-branded,
            accent-co: $color-accent-co-branded,
            co-brand-3: $color-brand-3,
        )
);

// BUTTON MAPS

$primary-button-map: (
    color: $color-accent-co-branded,
    hover: $color-accent-co-branded-hover,
    disabled: $color-accent-co-branded-disabled,
    accent: $color-brand-4-co-branded,
    link: $color-link-co-branded,
    link-hover: $color-link-co-branded-hover,
) !default;

$secondary-button-map: (
    color: $color-secondary-co-branded,
    hover: $color-secondary-co-branded-hover,
    disabled: $color-secondary-co-branded-disabled,
    accent: $color-white-co-branded,
);

$accent-button-map: (
    color: $color-accent-co-branded,
    hover: $color-accent-co-branded-hover,
    disabled: $color-accent-co-branded-disabled,
    accent: $color-white-co-branded,
);

$header-background-brand: $color-primary;

