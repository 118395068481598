@import '../base/mixins';

$grid-template-columns: (
    -2-repeat: repeat(2, 1fr),
    // @deprecated
    -3-repeat: repeat(3, 1fr),
    // @deprecated
    -4-repeat: repeat(4, 1fr),
    // @deprecated
    -2: repeat(2, 1fr),
    -3: repeat(3, 1fr),
    -4: repeat(4, 1fr)
);

.u-grid {
    display: grid;
    grid-gap: var(--spacer-default);
}

@each $key, $value in $grid-template-columns {
    .u-grid-col#{$key} {
        grid-template-columns: #{$value};

        @include up-to-and-including-tablet-landscape {
            grid-template-columns: repeat(2, 1fr);
        }

        @include up-to-and-including-phone-landscape {
            grid-template-columns: unset;
        }
    }
}

$grid-positions: (-start: 'start', -end: 'end', -center: 'center', -stretch: 'stretch');

@each $key, $value in $grid-positions {
    .u-grid-justify-items#{$key} {
        justify-items: #{$value};
    }

    .u-grid-align-items#{$key} {
        align-items: #{$value};
    }
}

$grid-numbers: 2, 3, 4, 5;

@each $number in $grid-numbers {
    .u-grid-column-span-#{$number} {
        grid-column: span #{$number};
    }

    .u-grid-row-span-#{$number} {
        grid-row: span #{$number};
    }
}

.u-grid-double-gap {
    grid-gap: var(--spacer-double);
}

.u-grid-half-gap {
    grid-gap: var(--spacer-sm);
}

.u-grid-no-gap {
    grid-gap: 0;
}

.u-grid-auto-rows {
    grid-auto-rows: 1fr;
}

.u-grid-auto-columns {
    grid-auto-columns: 1fr;
}

.u-row-equal-width-responsive {
    display: grid;

    @include up-to-and-including-phone-landscape {
        grid-template-columns: 1fr;
        gap: 0;
    }

    @include tablet-portrait-up {
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
    }
}

.u-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.u-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
}

.u-grid-gap-default {
    grid-gap: var(--spacer-default);
}

.u-grid-gap-md {
    grid-gap: var(--spacer-md);
}
