//----------------------------------------
// Utils : Link
//----------------------------------------
@import '../base/variables';

.u-link {
    text-decoration: underline;
    color: $color-link;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &.--is-tel {
        color: $color-brand-4;
    }
}

.u-link-muted {
    text-decoration: underline;
    color: $color-gray;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.u-link-no-color {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400 !important;

    &:hover {
        text-decoration: none;
    }
}

.u-link-default {
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        text-decoration: none;
    }
}

.u-link-inverted {
    text-decoration: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
}

.u-link-no-style {
    text-decoration: none;
    color: inherit;
}

.u-cursor-pointer {
    cursor: pointer;
}
