@import '../base/variables';

$border-collection: (
    radius: $common-border-radius,
    radius-medium: $common-border-radius-medium,
    radius-lg: $common-border-radius-large,
    radius-xl: $common-border-radius-extra-large,
    radius-xxl: $common-border-radius-extra-extra-large,
    color: $common-border-color,
    color-light: $common-border-light-color,
);

$positions: top, right, bottom, left;

:root {
    --divider-color: #{$divider-color};

    @each $key, $value in $border-collection {
        --border-#{$key}: #{$value};
    }

    @each $pos in $positions {
        .u-border-#{$pos} {
            border-#{$pos}: 1px solid var(--color-gray-lightest);
        }
    }
}

%divider {
    --divider-thickness: 1px;
    --divider-gap: $spacer;
    --divider-shrink: 0px;
    position: relative;

    &.u-divider-thick-medium {
        --divider-thickness: 2px;
    }

    &.u-divider-no-gap {
        --divider-gap: 0;
    }
}

.u-divider-after-horizontal {
    @extend %divider;
    padding-bottom: --divider-gap;
    margin-bottom: --divider-gap;

    &::before {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: var(--divider-thickness);
        background-color: var(--divider-color);
        bottom: 0;
    }
}

.u-divider-before-vertical {
    @extend %divider;
    padding-left: --divider-gap;
    margin-left: --divider-gap;

    &::before {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: var(--divider-thickness);
        height: calc(100% - 2 * var(--divider-shrink));
        margin: var(--divider-shrink) 0;
        background-color: var(--divider-color);
    }
}

.u-stroke-secondary {
    border: 1px solid var(--stroke-secondary);
}

.u-box-shadow-default {
    box-shadow: var(--box-shadow-default);
}
