//----------------------------------------
// Utils : Spacing
//----------------------------------------

@use 'sass:math';
@import '../base/mixins';

$margin-collection: (
    '': $spacer,
    -xxs: $spacer-xxs,
    -xs: $spacer-xs,
    -half: $spacer-sm,
    -sm-md: $spacer * math.div(3, 4),
    -md: $spacer-md,
    -lg: $spacer-lg,
    -double: $spacer * 2,
    -double-negative: -1 * $spacer * 2,
    -triple: $spacer * 3,
    -quadruple: $spacer * 4,
    -none: 0,
);

$position-collection: ('', -top, -bottom, -left, -right);

.u-no-padding-right {
    padding-right: 0 !important;
}

.u-padding-left-container {
    padding-left: calc(calc(100vw - #{$container-max-width}) / 2);
}

.u-no-padding-left {
    padding-left: 0 !important;
}

.u-padding-x-axis {
    padding-left: var(--spacer-default) !important;
    padding-right: var(--spacer-default) !important;
}

.u-padding-x-axis-double {
    padding-left: var(--spacer-double) !important;
    padding-right: var(--spacer-double) !important;
}

.u-padding-x-axis-none {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.u-padding-y-axis {
    padding-top: var(--spacer-default) !important;
    padding-bottom: var(--spacer-default) !important;
}

.u-padding-y-axis-half {
    padding-top: var(--spacer-sm) !important;
    padding-bottom: var(--spacer-sm) !important;
}

.u-padding-y-axis-quarter {
    padding-top: var(--spacer-xs) !important;
    padding-bottom: var(--spacer-xs) !important;
}

.u-padding-y-axis-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.u-padding-top-quarter {
    padding-top: var(--spacer-xs) !important;
}

@mixin padding($pos, $value) {
    padding#{$pos}: #{$value} !important;
}

@mixin margin($pos, $value) {
    margin#{$pos}: #{$value} !important;
}

@each $key, $value in $margin-collection {
    @each $pos in $position-collection {
        .u-margin#{$pos}#{$key} {
            @include margin($pos, $value);
        }

        .u-padding#{$pos}#{$key} {
            @include padding($pos, $value);
        }

        .u-margin#{$pos}#{$key}-phone-landscape-up {
            @include phone-landscape-up {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-phone-landscape-up {
            @include phone-landscape-up {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-tablet-portrait-up {
            @include tablet-portrait-up {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-tablet-portrait-up {
            @include tablet-portrait-up {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-tablet-landscape-up {
            @include tablet-landscape-up {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-tablet-landscape-up {
            @include tablet-landscape-up {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-desktop-up {
            @include desktop-up {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-desktop-up {
            @include desktop-up {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-up-to-and-including-phone-landscape {
            @include up-to-and-including-phone-landscape {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-up-to-and-including-phone-landscape {
            @include up-to-and-including-phone-landscape {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-up-to-and-including-tablet-portrait {
            @include up-to-and-including-tablet-portrait {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-up-to-and-including-tablet-portrait {
            @include up-to-and-including-tablet-portrait {
                @include padding($pos, $value);
            }
        }

        .u-margin#{$pos}#{$key}-up-to-and-including-tablet-landscape {
            @include up-to-and-including-tablet-landscape {
                @include margin($pos, $value);
            }
        }

        .u-padding#{$pos}#{$key}-up-to-and-including-tablet-landscape {
            @include up-to-and-including-tablet-landscape {
                @include padding($pos, $value);
            }
        }
    }

    .u-margin-y-axis#{$key} {
        margin-top: $value !important;
        margin-bottom: $value !important;
    }

    .u-margin-x-axis#{$key} {
        margin-left: $value !important;
        margin-right: $value !important;
    }

    .u-padding-y-axis#{$key} {
        padding-top: $value !important;
        padding-bottom: $value !important;
    }

    .u-padding-x-axis#{$key} {
        padding-left: $value !important;
        padding-right: $value !important;
    }
}

.u-no-margin-right {
    margin-right: 0 !important;
}

.u-no-margin-left {
    margin-left: 0 !important;
}

.u-margin-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.u-padding-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.u-margin-y-axis {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.u-margin-x-axis {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
}

.u-margin-0-auto {
    margin: 0 auto !important;
}

.u-margin-x-axis-auto {
    margin-left: auto !important;
    margin-right: auto !important;
}

$spacer-collection: (
    default: $spacer,
    xs: $spacer-xs,
    sm: $spacer-sm,
    sm-md: $spacer * math.div(3, 4),
    md: $spacer-md,
    lg: $spacer-lg,
    double: $spacer * 2,
    triple: $spacer * 3,
);

:root {
    @each $size, $value in $spacer-collection {
        --spacer-#{$size}: #{$value};
    }
}
