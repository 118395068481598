@import '../base/variables';

$button-width-md: 38px;
$button-width-sm: 33px;

@mixin smvd-ui-button-theme($context, $color-rgb-map) {
    $_transition: 150ms;
    $_color: map-get($color-rgb-map, color);
    $_color-hover: map-get($color-rgb-map, hover);
    $_color-disabled: map-get($color-rgb-map, disabled);
    $_color-active: map-get($color-rgb-map, active);

    $_color-accent: map-get($color-rgb-map, accent);
    $_color-accent-disabled: map-get($color-rgb-map, accentDisabled);
    $_color-link: map-get($color-rgb-map, link);
    $_color-link-hover: map-get($color-rgb-map, link-hover);

    $_color-link: if($_color-link, $_color-link, $_color);
    $_color-link-hover: if($_color-link-hover, $_color-link-hover, $_color-hover);

    .ui-button {
        cursor: pointer;
        background-color: transparent;

        &.ui-button-#{$context} {
            padding: $spacer-sm $spacer;
            line-height: $font-line-height-sm;
            transition:
                border #{$_transition} linear,
                color #{$_transition} linear,
                background #{$_transition} linear,
                opacity #{$_transition} linear;
            font-weight: $font-weight-medium;
            font-size: $font-size-default;
            height: $button-height-md;
            white-space: nowrap;

            &.ui-button-xs {
                height: $button-height-xs;
                font-size: $font-size-xs !important;
                padding: 0 $spacer;
            }

            &.ui-button-sm {
                height: $button-height-sm;
                font-size: $font-size-sm;
            }

            &.mat-basic-button,
            &.mat-mdc-outlined-button,
            &.mat-mdc-raised-button,
            &.mat-mdc-unelevated-button {
                border-width: 1px;
                border-style: solid;
            }

            &:disabled {
                cursor: not-allowed;
            }

            @if ($_color-active) {
                &:active {
                    background: $_color-active !important;
                }
            }

            &.mat-basic-button,
            &.mat-mdc-raised-button,
            &.mat-mdc-unelevated-button {
                color: $_color-accent;
                background: $_color;
                border-color: $_color;

                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        &:not(:disabled) {
                            background-color: $_color-hover;
                            border-color: $_color-hover;
                        }
                    }
                }

                &:disabled {
                    border-color: $_color-disabled;
                    background: $_color-disabled;
                    color: $_color-accent-disabled;
                }
            }

            &.mat-mdc-outlined-button {
                color: $_color;
                border-color: currentColor;

                @media (hover: hover) {
                    &:hover {
                        &:not(:disabled) {
                            background: $_color-hover;
                            color: $_color-accent;
                        }
                    }
                }

                &:disabled {
                    border-color: currentColor;
                    color: $_color-disabled;
                }
            }

            &.mat-mdc-icon-button {
                display: inline-flex;
                align-items: center;
                padding: 0;

                app-icon {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    min-width: unset;
                }
            }

            &.mat-mdc-flat-transparent-button,
            &.mat-mdc-icon-button:not(.mat-mdc-outlined-button):not(.mat-mdc-unelevated-button) {
                color: $_color;
                background-color: transparent;
                border-color: transparent !important;

                @media (hover: hover) {
                    &:hover {
                        &:not(:disabled) {
                            color: $_color-hover;
                            background: transparent;
                        }
                    }
                }

                &:disabled {
                    color: $_color-disabled;
                    border-color: transparent;
                    background: transparent;
                }
            }

            &.mat-mdc-link-button,
            &.mat-mdc-link-reversed-button {
                color: $_color-link;
                background-color: transparent;
                border: none !important;

                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        &:not(:disabled) {
                            color: $_color-link-hover;
                            background-color: transparent;
                        }
                    }
                }

                &:disabled {
                    color: $_color-disabled;
                    background: transparent;
                }
            }

            &.mat-mdc-link-button span {
                font-weight: normal;
                text-decoration: underline;
                white-space: nowrap;

                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        &:not(:disabled) {
                            text-decoration: none;
                        }
                    }
                }
            }

            &.mat-mdc-link-reversed-button span {
                font-weight: normal;
                text-decoration: none;

                @media (hover: hover) {
                    &:hover,
                    &:focus {
                        &:not(:disabled) {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

:not(.ui-button-group-vertical) {
    & > .mat-mdc-link-button + .mat-mdc-button-base,
    & > .mat-mdc-link-reverse-button + .mat-mdc-button-base,
    & > .mat-mdc-button-base + .mat-mdc-link-button,
    & > .mat-mdc-button-base + .mat-mdc-link-reverse-button {
        margin-left: var(--spacer-default);
    }
}

mat-dialog-actions.mat-dialog-actions .mat-mdc-link-button + .mat-mdc-button-base,
mat-dialog-actions.mat-dialog-actions .mat-mdc-link-reverse-button + .mat-mdc-button-base,
mat-dialog-actions.mat-dialog-actions .mat-mdc-button-base + .mat-mdc-link-button,
mat-dialog-actions.mat-dialog-actions .mat-mdc-button-base + .mat-mdc-link-reverse-button {
    margin-left: var(--spacer-default);
}

.mat-mdc-button-base.mat-mdc-icon-button.ui-button {
    width: $button-height-md !important;
    height: $button-height-md !important;
    justify-content: center;
    padding: 0;
    text-decoration: none;

    app-icon {
        display: flex;
        width: auto;
    }

    &.ui-button-xs {
        width: $button-height-xs !important;
        height: $button-height-xs !important;
    }

    &.ui-button-sm {
        width: $button-height-sm !important;
        height: $button-height-sm !important;
    }
}

.mat-mdc-button-base.mat-mobile-icon-button.ui-button {
    @include up-to-and-including-phone-portrait {
        width: $button-height-md;
        justify-content: center;
        padding: 0;

        app-icon {
            display: flex;
            width: auto;
        }

        &.ui-button-xs {
            width: $button-height-xs;
        }

        &.ui-button-sm {
            width: $button-height-sm;
        }
    }
}

$primary-button-map: (
    color: $color-primary,
    hover: rgba($color-primary, 0.75),
    disabled: rgba($color-primary, 0.3),
    accent: $color-white,
) !default;

$alt-button-map: (
    color: $color-gray-ultralight,
    hover: $color-gray-lightest,
    disabled: rgba($color-gray-ultralight, 0.5),
    accentDisabled: rgba($color-primary-alt, 0.3),
    accent: $color-primary-alt,
    active: $color-gray-light,
) !default;

$primary-dark-button-map: (
    color: $color-success-dark,
    hover: rgba($color-success-dark, 0.75),
    disabled: rgba($color-success-dark, 0.3),
    accent: $color-white,
) !default;

$gray-light-button-map: (
    color: $color-white,
    hover: rgba($color-gray-lightest, 0.75),
    disabled: rgba($color-gray-lightest, 0.3),
    accent: $color-success-dark,
) !default;

$secondary-button-map: (
    color: $color-secondary,
    hover: rgba($color-secondary, 0.75),
    disabled: rgba($color-secondary, 0.3),
    accent: $color-white,
) !default;

$tertiary-button-map: (
    color: var(--background-tertiary-default),
    hover: var(--background-tertiary-hover),
    active: var(--background-tertiary-pressed),
    disabled: var(--background-tertiary-disabled),
    accent: var(--content-brand-default),
    accentDisabled: var(--content-tertiary-default),
) !default;

$accent-button-map: (
    color: $color-accent,
    hover: rgba($color-accent, 0.75),
    disabled: rgba($color-accent, 0.3),
    accent: $color-white,
) !default;

$muted-button-map: (
    color: $color-gray,
    hover: $color-gray-dark,
    disabled: $color-gray-light,
    accent: $color-white,
) !default;

$warning-button-map: (
    color: $color-error,
    hover: $color-error-dark,
    disabled: $color-error-light,
    accent: $color-white,
) !default;

$text-button-map: (
    color: inherit,
    hover: inherit,
    disabled: $color-gray,
    accent: inherit,
) !default;

@include smvd-ui-button-theme('primary', $primary-button-map);
@include smvd-ui-button-theme('alt', $alt-button-map);
@include smvd-ui-button-theme('primary-dark', $primary-dark-button-map);
@include smvd-ui-button-theme('gray-light', $gray-light-button-map);
@include smvd-ui-button-theme('secondary', $secondary-button-map);
@include smvd-ui-button-theme('accent', $accent-button-map);
@include smvd-ui-button-theme('tertiary', $tertiary-button-map);
@include smvd-ui-button-theme('muted', $muted-button-map);
@include smvd-ui-button-theme('warning', $warning-button-map);
@include smvd-ui-button-theme('text', $text-button-map);

p,
span {
    .ui-button.mat-mdc-link-button,
    .ui-button.mat-mdc-link-reversed-button {
        padding: 0;
        height: auto;
    }
}

.ui-button-md app-icon i {
    height: var(--font-size-md) !important;
    line-height: var(--font-size-md) !important;

    &.fab::before,
    .fab::before {
        font-size: var(--font-size-md) !important;
        height: var(--font-size-md) !important;
        line-height: var(--font-size-md) !important;
    }
}

.ui-button-sm app-icon i {
    height: var(--font-size-sm) !important;
    line-height: var(--font-size-sm) !important;

    &.fab::before {
        font-size: var(--font-size-sm) !important;
        height: var(--font-size-sm) !important;
        line-height: var(--font-size-sm) !important;
    }
}

// EDGE BUTTONS

.ui-edge-button {
    position: absolute;

    &.ui-edge-button-stick-to-screen {
        position: fixed;
    }

    &.ui-edge-button-bottom,
    &.ui-edge-button-bottom-start,
    &.ui-edge-button-bottom-end {
        border-top-right-radius: var(--border-radius-lg);
        border-top-left-radius: var(--border-radius-lg);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &.ui-edge-button-top,
    &.ui-edge-button-top-start,
    &.ui-edge-button-top-end {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: var(--border-radius-lg);
        border-bottom-left-radius: var(--border-radius-lg);
    }

    &.ui-edge-button-right,
    &.ui-edge-button-right-start,
    &.ui-edge-button-right-end {
        border-top-right-radius: 0;
        border-top-left-radius: var(--border-radius-lg);
        border-bottom-right-radius: 0;
        border-bottom-left-radius: var(--border-radius-lg);
    }

    &.ui-edge-button-left,
    &.ui-edge-button-left-start,
    &.ui-edge-button-left-end {
        border-top-right-radius: var(--border-radius-lg);
        border-top-left-radius: 0;
        border-bottom-right-radius: var(--border-radius-lg);
        border-bottom-left-radius: 0;
    }

    &.ui-edge-button-left,
    &.ui-edge-button-left-start,
    &.ui-edge-button-left-end &.ui-edge-button-bottom-start,
    &.ui-edge-button-top-start {
        left: 0;
    }

    &.ui-edge-button-right,
    &.ui-edge-button-right-start,
    &.ui-edge-button-right-end,
    &.ui-edge-button-bottom-end,
    &.ui-edge-button-top-end {
        right: 0;
    }

    &.ui-edge-button-top,
    &.ui-edge-button-top-start,
    &.ui-edge-button-top-end,
    &.ui-edge-button-left-start,
    &.ui-edge-button-right-start {
        top: 0;
    }

    &.ui-edge-button-bottom,
    &.ui-edge-button-bottom-start,
    &.ui-edge-button-bottom-end,
    &.ui-edge-button-left-end,
    &.ui-edge-button-right-end {
        bottom: 0;
    }

    &.ui-edge-button-stick-to-screen {
        position: fixed;

        &.ui-edge-button-top-end,
        &.ui-edge-button-bottom-end {
            right: var(--spacer-double);
        }

        &.ui-edge-button-top-start,
        &.ui-edge-button-bottom-start {
            left: var(--spacer-double);
        }

        &.ui-edge-button-left-start,
        &.ui-edge-button-right-start {
            top: var(--spacer-double);
        }

        &.ui-edge-button-left-end,
        &.ui-edge-button-right-end {
            bottom: var(--spacer-double);
        }
    }
}
